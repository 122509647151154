<template>
  <div class="header">
    <i class="fl left_30 pointer" :class="isCollapse === '1' ? 'el-icon-s-unfold' : 'el-icon-s-fold'" style="line-height: 60px" @click="changeCollapseStatus()"></i>
    <div class="fl">
      <el-breadcrumb separator="/" class="left_10">
        <el-breadcrumb-item v-for="(item,index) in menuList" :key="index" :to="{path:item.path,query:item.query,params:item.params}">{{ item.meta.title }}</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="ft">
      <div class="icon fl">
        <i class="el-icon-user"></i>
      </div>
      <el-dropdown class="fl user-name" @command="handleCommand">
        <span class="el-dropdown-link">{{ userInfo ? userInfo.name : '' }}<i class="el-icon-caret-bottom"></i></span>
        <el-dropdown-menu slot="dropdown">
          <!--          <el-dropdown-item>修改密码</el-dropdown-item>-->
          <el-dropdown-item :command="1">退出登录</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
  </div>
</template>

<script>
import bus from "@/assets/js/bus";
import { mapState } from 'vuex'
export default {
  name: "Head",
  data(){
    return {
      menuList:[]
    }
  },
  computed:{
    userInfo(){
      return window.localStorage.getItem('userInfo') && JSON.parse(window.localStorage.getItem('userInfo'))
    },
    ...mapState({
      isCollapse:state => state.isCollapse
    })
  },
  methods:{
    handleCommand(command){
      if(command === 1) this.logout();
    },
    logout(){
      window.localStorage.setItem("userInfo",JSON.stringify({}));
      this.$router.push("/login");
    },
    changeCollapseStatus(){
      this.$store.commit('changeCollapseStatus');
    }
  },
  mounted() {
    this.menuList = window.localStorage.getItem("menuList") && JSON.parse(window.localStorage.getItem("menuList"));
    bus.$on("getMenuList",() => {
      this.menuList = window.localStorage.getItem("menuList") && JSON.parse(window.localStorage.getItem("menuList"));
    })
  }
}
</script>

<style scoped lang="scss">
.header {
  height: 60px;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04);
  line-height: 60px;
  .el-breadcrumb{
    line-height: 60px;
  }
  .icon {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    text-align: center;
    line-height: 20px;
    border: 1px solid #000;
    margin-top: 20px;
    i {
      font-size: 14px;
    }
  }
  .user-name{
    margin-left: 10px;
    line-height: 20px;
    margin-top: 20px;
    cursor: pointer;
    i{
      margin: 0 20px 0 10px;
    }
  }
}
</style>
