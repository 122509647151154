<template>
  <el-submenu :index="menu.id">
    <template slot="title">
      <i class="yzc" :class="menu.icon"></i>
      <span>{{ menu.name }}</span>
    </template>
    <template v-for="item in menu.children">
      <el-menu-item @click="goPage(item)" :index="item.path" :key="item.id" v-if="!item.children">
        <i class="yzc" :class="item.icon"></i>
        <span slot="title">{{ item.name }}</span>
      </el-menu-item>
      <SubMenu :menu="item" v-if="item.children" :key="item.id"></SubMenu>
    </template>

  </el-submenu>
</template>
<script>
import SubMenu from '@/views/common/SubMenu'
export default {
  name: "SubMenu",
  components: {
    SubMenu
  },
  props: {
    menu: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  methods:{
    goPage(item){
      this.$router.push(item.path)
    }
  }
}
</script>

<style scoped>
i.yzc{
  margin-right: 5px;
  color: #fff;
}

.el-menu-item.is-active i.yzc{
  color: rgb(64, 158, 255);
}

</style>
