<template>
  <div class="content">
    <div class="fl menu" :class="{collapse:isCollapse === '1'}">
      <div class="menu-title" v-if="isCollapse === '0'">医知巢后台管理系统</div>
      <div class="menu-title collapse-title" v-if="isCollapse === '1'">
        医
      </div>
      <Menu></Menu>
    </div>
    <div class="page-right fl" :class="{collapse:isCollapse === '1'}">
      <Head></Head>
      <div class="main">
        <router-view />
      </div>
    </div>
  </div>
</template>

<script>
import Menu from "@/views/common/Menu";
import Head from "@/views/common/Head";
import {mapState} from 'vuex'
export default {
  name: "Main",
  components: {
    Menu,
    Head
  },
  computed:{
    ...mapState({
      isCollapse:state => state.isCollapse
    })
  }
}
</script>

<style scoped lang="scss">
.content {
  width: 100%;
  height: 100%;
  .menu{
    width: 200px;
    height: 100%;
    background-color: rgb(84, 92, 100);
    transition: width 0.3s;
    .menu-title{
      color: #fff;
      text-align: center;
      line-height: 60px;
    }
    .collapse-title{
      width: 40px;
      height: 40px;
      margin-left: 10px;
      margin-top: 10px;
      margin-bottom: 10px;
      line-height: 40px;
      text-align: center;
      font-size: 18px;
      border: 1px solid #fff;
      color: #fff;
      border-radius: 50%;
    }
  }
  .menu.collapse{
    width: 64px;
  }
  .page-right{
    width: calc(100% - 200px);
    height: 100%;
    transition: width 0.3s;
    background-color: #efefef;
    .main{
      width: calc(100% - 20px);
      height: calc(100% - 80px);
      margin: 10px;
      background-color: #fff;
      overflow: auto;
      box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04);
      border-radius: 3px;
      &>div{
        height: 100%;
      }
    }
  }
  .page-right.collapse{
    width: calc(100% - 64px);
  }
}
.content .page-right .main > div[data-v-df95165a]{
  height: 95%;
}
</style>
