<template>
  <el-scrollbar class="page-scroll">
    <el-menu
            :default-active="$route.path"
            class="el-menu-vertical-demo"
            :collapse="isCollapse === '1'"
            @open="handleOpen"
            @close="handleClose"
            :show-timeout="0"
            :hide-timeout="0"
            background-color="#545c64"
            text-color="#fff"
            active-text-color="#409eff">
      <template v-for="item in menuList">
        <el-menu-item @click="goPage(item)" :index="item.path" :key="item.id" v-if="!item.children">
          <i class="yzc" :class="item.icon"></i>
          <span slot="title">{{ item.name }}</span>
        </el-menu-item>
        <SubMenu :menu="item" v-if="item.children" :key="item.id"></SubMenu>
      </template>
    </el-menu>
  </el-scrollbar>
</template>

<script>
import SubMenu from "@/views/common/SubMenu";
import bus from "@/assets/js/bus";
import {mapState} from 'vuex'
export default {
  name: "Menu",
  components: {
    SubMenu
  },
  computed:{
    ...mapState({
      isCollapse:(state) => state.isCollapse
    })
  },
  watch:{
    $route(val){
      this.getNowMenu(val.path)
    }
  },
  data() {
    return {
      menuList: [
      ],
      allMenu:[]
    }
  },
  methods: {
    getNowMenu(path){
      for(let i = 0 ; i < this.allMenu.length ; i++){
        if(path === this.allMenu[i].component){
          let  a = []
          a.push(this.allMenu[i])
          window.localStorage.setItem("menuList",JSON.stringify(a));
          bus.$emit("getMenuList");
          break;
        }
      }
    },
    handleOpen(key, keyPath) {
      console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath);
    },
    goPage(item){
      this.$router.push(item.path);
    },
    dealMenuList(menu){
      let nowArr = [];
      for (let i = 0; i < menu.length; i++) {
        let item = menu[i];
        let nowList = {
          name:item.meta.title,
          path:item.component,
          id : item.meta.title,
          icon:item.meta.icon
        };
        if(item.children != null && item.children.length > 0){
          let childerArr = this.dealMenuList(item.children);
          nowList.children = childerArr;
        }
        nowArr.push(nowList);
      }
      return nowArr;
    }
  },
  created(){
    let  a = JSON.parse(window.localStorage.getItem("userInfo"))
    let mobile = a.mobile
    this.$axios.get("/admin/acl/index/menu",{mobile:mobile},(res) => {
      // console.log("aaaa"+JSON.parse(window.localStorage.getItem("userInfo")))
      this.loading = false;
      if(res.code === 100){
        //this.menuList = res.data.permissionList
        this.menuList = this.dealMenuList(res.data.permissionList);
        // this.$message.success('关闭订单成功');
        this.getNowMenu(this.$route.path);
      }
    })
  },
  mounted() {
    this.allMenu = [];
  }
}
</script>

<style scoped lang="scss">
.el-menu-item, .el-submenu {
  width: 200px;
  transition: width 0.3s;

}
.el-menu{
  border-right: none ;
}
.el-menu--collapse .el-menu-item, .el-menu--collapse .el-submenu {
  width: 64px;
}

i.yzc{
  margin-right: 5px;
  color: #fff;
}

.el-menu-item.is-active i.yzc{
  color: rgb(64, 158, 255);
}
.page-scroll {
  height: 90%;
}

::v-deep .el-scrollbar__wrap{
  overflow-x: hidden;
}

</style>
